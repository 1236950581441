/* Tables */

.table {
  margin-bottom: 0;
  thead {
    th {
      border-top: 0;
      border-bottom-width: 1px;
      font-weight: 600;
      font-size: .875rem;
      i {
        margin-left: 0.325rem;
      }
    }
  }
  th,
  td {
    vertical-align: middle;
    line-height: 1;
    white-space: nowrap;
    padding:$table-cell-padding;
  }
  td {
    font-size: $default-font-size;
    img {
      width: 36px;
      height: 36px;
      border-radius: 100%;
    }
    .badge {
      margin-bottom: 0;
    }
  }
  tr {
    &:last-child {
      td {
        border-bottom: none;
      }
    }
  }
  &.table-borderless {
    border: none;
    tr,
    td,
    th {
      border: none;
    }
  }
  &.table-bordered {
    border-top: 1px solid $border-color;
  }

  &.select-table {
    tr {
      td {
        &:first-child {
          padding-right: 10px;
        }
      }
    }
    th {
      color: $select-table-head;
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 15px;
      text-transform: uppercase;
      padding-right: 5px;
      padding-left: 0;
      .form-check {
        margin-bottom: 0;
        width: 20px;
      }
    }
    td {
      font-family: Manrope;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: $select-table-cell-color;
      padding-top: 14px;
      padding-bottom: 14px;
      padding-left: 0;
      .form-check {
        width: 20px;
      }
      h6 {
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: $black;
        margin-bottom: 5px;
      }
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: $text-muted;
        margin-bottom: 0;
      }
      img {
        width: 44px;
        height: 44px;
        border-radius: 10px;
        margin-right: 15px;
      }
      .max-width-progress-wrap {
        width: 120px;
      }
      .form-check {
        margin-bottom: 0;
      }
    }
  }
}
.table > :not(:last-child) > :last-child > *, .jsgrid .jsgrid-table > :not(:last-child) > :last-child > * {
  border-bottom-color: $border-color;
}
